<template>
  <v-dialog max-width="640px" v-model="dialog" transition="dialog-transition" open-on-focus>
    <template v-slot:activator="{ on }" >
      <v-text-field ref="element" dense outlined :label="label || 'Time Range'" clearable v-on="on"
        :error-messages="error" class="" :value="formattedRangevalue"
        @click:clear="content = ['',''];tempContent = ['',''];updateValue()" :hide-details="hidedetails" :disabled="disabled"
      ></v-text-field>
    </template>
    <v-card elevation="0" flat tile>
      <v-card-text class="pa-0 pt-3">
        <div class="display-ib pl-5">
          <h2 class="text-left mb-2 pl-4">Start:</h2>
          <v-time-picker elevation="0" v-model="tempContent[0]" color="primary" scrollable :max="tempContent[1]">
          </v-time-picker>
        </div>
        <div class="display-ib px-5">
          <h2 class="text-left mb-2 pl-4">End:</h2>
          <v-time-picker elevation="0" v-model="tempContent[1]" color="primary" scrollable :min="tempContent[0]">
          </v-time-picker>
        </div>
      </v-card-text>
      <v-card-text class="text-right pt-4">
        <v-btn text small @click="dialog = false;tempContent = content;updateValue()">close</v-btn>
        <v-btn small elevation="0" color="primary" @click="content = tempContent; dialog = false;updateValue()" :disabled="tempContent[0] === '' || tempContent[1] === ''">ok</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    activated() {
      this.setValue();
      this.updateValue();
    },
    created() {
      this.setValue();
      this.updateValue();
    },
    data: () => ({
      dialog: false,
      content: ['',''],
      tempContent: ['','']
    }),
    props: {
      error: {
        type: [String, Array],
      },
      label: {
        type: String,
      },
      value: {
        type: Array
      },
      hidedetails: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
      // required: {
      //   type: Boolean,
      //   default: false
      // }
    },
    methods: {
      setValue: function(){
        this.content = ["", ""];
        if(this.value instanceof Array){
          if(this.value.length === 2){
            let v = this.$nova.formatTime(this.value);
            if(v.length === 2) this.content = this.value;
          }
        }
      },
      updateValue: function () {
        this.$emit('input', this.content);
        this.$emit('change', this.content);
      },
      setFocus: function(){
        this.$nextTick(() => {
          this.$refs.element.$el.querySelector('input').focus();
        })
      },
      setBlank: function(){
        this.content = ['',''];
        this.tempContent = ['',''];
      }
    },
    computed:{
      formattedRangevalue(){
        let v = this.$nova.formatTime(this.content);
        if(v.length === 2) return v.join(" - ");
        else {
          this.setBlank();
          return [];
        }
      }
    },
    watch: {
      value: function(){
        this.setValue();
      }
    }
  }
</script>